<template>
  <svg
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    color="#a4241c"
  >
    <path :d="svg[shape][0]" :fill="svg[shape][1]"></path>
    <path :d="svg[shape][2]"></path>
  </svg>
</template>

<script>
export default {
  name: 'MySvg',
  data() {
    return {
      svg: {
        phone: [
          'M0 0h24v24H0z',
          'none',
          'M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z',
        ],
        mail: [
          'M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z',
          'currentColor',
          'M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z',
        ],
        place: [
          'M0 0h24v24H0z',
          'none',
          'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z',
        ],
        youtube: [
          'M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z',
        ],
        podcast: [
          'M1.5 9.68c-.83 0-1.5.67-1.5 1.5V12.81a1.5 1.5 0 1 0 3 0v-1.63c0-.83-.67-1.5-1.5-1.5zM22.5 9.68c-.83 0-1.5.67-1.5 1.5V12.81a1.5 1.5 0 1 0 3 0v-1.63c0-.83-.67-1.5-1.5-1.5zM6.68 14.59c-.83 0-1.5.67-1.5 1.5V17.72a1.5 1.5 0 1 0 3 0V16.1c0-.83-.67-1.5-1.5-1.5zM6.68 4.77c-.83 0-1.5.67-1.5 1.5V11.63a1.5 1.5 0 0 0 3 0V6.26c0-.83-.67-1.5-1.5-1.5zM17.32 4.77c-.83 0-1.5.67-1.5 1.5V7.91a1.5 1.5 0 0 0 3 0V6.27c0-.83-.67-1.5-1.5-1.5zM12 0c-.83 0-1.5.67-1.5 1.5v1.63a1.5 1.5 0 1 0 3 0V1.5C13.5.67 12.83 0 12 0zM12 19.36c-.83 0-1.5.67-1.5 1.5V22.5a1.5 1.5 0 1 0 3 .01v-1.64c0-.82-.67-1.5-1.5-1.5zM17.32 10.9c-.83 0-1.5.68-1.5 1.5v5.33a1.5 1.5 0 0 0 3 0V12.4c0-.83-.67-1.5-1.5-1.5zM12 6.13c-.83 0-1.5.68-1.5 1.5v8.73a1.5 1.5 0 0 0 3 0V7.64c0-.83-.67-1.5-1.5-1.5z',
        ],
        instagram: [
          'M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z',
        ],
      },
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: 15,
    },
    shape: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped>
svg {
  margin-top: 3px;
  margin-right: 8px;
}
</style>
