<template>
  <InstrGen />
</template>

<script>
import InstrGen from './components/InstrGen.vue';

export default {
  name: 'App',
  components: {
    InstrGen,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
}
body {
  background: rgb(204, 204, 204);
}
@page {
  size: A4;
  margin: 0;
}
</style>
