module.exports = [
  {
    id: '1',
    title: 'BAREVNÝ KÓD',
    kategory: 'logika, představivost, percepce',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Hlavolamová hra založená na skládání obrazce z jednotlivých dílků. Celkem obsahuje 100 různých rébusů dle náročnosti. Skládá se z 18 průhledných destiček s různými tvary a barvami, ze kterých se správným vrstvením a natočením docílí výsledku dle zadání.',
    tips: 'Na pomůcce lze také procvičovat barvy a tvary.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/44/0b/AsPHct9D_t.jpg',
  },
  {
    id: '2',
    title: 'ZÁMECKÉ SCHODY',
    kategory: 'logika, představivost, percepce',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Hlavolamová hra, jejímž principem je z dřevěných dílků postavit schody a mosty tak, aby se princ dostal k princezně. Úkoly jsou rozděleny od jednoduchých po složité. Zaměřuje se na rozvoj logického myšlení a prostorové představivosti. ',
    tips:
      'Na pomůcce lze také procvičovat barvy a pozice před, za, na, mezi aj.',
    price: '300',
    count: '1',
    img: 'https://thumbs2.imgbox.com/eb/d3/TNif0hHa_t.jpg',
  },
  {
    id: '3',
    title: 'ZÁHADNÝ HRAD',
    kategory: 'logika, představivost, percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hlavolamová hra jejímž cílem je sestavit různé dřevěné dílky tak, aby společně vytvořily hrad dle zadání. Hra má úroveň od začátečníka až po experty. Rozvíjí logické myšlení a prostorovou představivost.',
    tips: 'Na pomůcce lze také procvičovat barvy a velikost.',
    price: '300',
    count: '1',
    img: 'https://thumbs2.imgbox.com/33/18/NXQ0MsCY_t.jpg',
  },
  {
    id: '4',
    title: 'CROSS OUT – co do řady nepatří',
    kategory: 'logika, řeč',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Naučná hra u které se ze skupiny obrázků vybíra ten, který se zbylými nesouvisí.  ',
    tips: 'Na pomůcce lze také procvičovat slovní zásobu, řeč a kategorie.',
    price: '400',
    count: '1',
    img: 'https://thumbs2.imgbox.com/e9/21/1eVzdvye_t.jpg',
  },
  {
    id: '5',
    title: 'KRÁLÍK KUK',
    kategory: 'logika, představivost, percepce',
    age: 'od 2 let',
    playersCount: '1',
    description:
      'Logická hra, která se skládá ze tří různých dřevěných dílků, králíčka a 60 zajímavých rébusů. Principem hry je umístit králíka tak, jako je na předloze. Hra rozvíjí logické uvažování a prostorovou orientaci.',
    tips:
      'Na pomůcce lze také procvičovat barvy a prostorové orientace před, za, na, mezi aj.',
    price: '300',
    count: '1',
    img: 'https://thumbs2.imgbox.com/9a/1c/t4GTf1yW_t.jpg',
  },
  {
    id: '6',
    title: 'ZAJÍC V PYTLI',
    kategory: 'percepce, řeč, představivost',
    age: 'od 3 let',
    playersCount: '1 – 6 ',
    description:
      'Hra rozvíjí představivost, slovní zásobu a schopnost rozeznávat věci hmatem – děti totiž při hře například vytahují z pytlíku různé tvary, snaží se je hmatem rozpoznat a pak správně pojmenovat; v případě úspěchu pak ještě vyhledají obdobný obrázek na hrací ploše, aby na něj mohly umístit svou figurku. Hra umožňuje až tři herní varianty.',
    tips:
      'Na pomůcce lze také procvičovat slovní zásobu, řeč a kategorie. Hru lze propojit s různými dráhami nebo procvičováním porozumnění a prostorové orientace, např.: „Podej obrázek, který leží pod stolem.“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/12/70/1p4q39v2_t.jpg',
  },
  {
    id: '7',
    title: 'BIM BAM – hra se zvuky',
    kategory: 'percepce, představivost',
    age: 'od 4 let',
    playersCount: '1 – 4',
    description:
      'Zvuková hra, která obsahuje CD s 32 různými zvuky a 4 karty s tematikou koncertního sálu, školky, rušné ulice a pohádkové vesnice. Principem hry je to, aby dítě rozpoznalo zvuk z nahrávky a na příslušné kartě jej označilo buď žetonem s daným symbolem či ukázalo prstem. V návodu jsou popsány celkem tři možné hry.',
    tips: 'Na pomůcce lze také procvičovat slovní zásobu, řeč a kategorie.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/7b/38/49AKUj62_t.jpg',
  },
  {
    id: '8',
    title: 'FIGURÁČCI',
    kategory: 'tvary, barvy, hra s kostkou, společenská hra, percepce',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Naučná hra která obsahuje čtyři oboustranné karty s obrázky. Principem je co nejrychleji vyplnit na vybraném obrázku prázdná místa s geometrickými tvary. K dispozici jsou dvě kostky – s tvary a barvami, se kterými se hází současně. Vždy je možné doplnit jen ten dílek, který odpovídá tvarově i barvou a na hrací desce ještě není doplněn.',
    tips:
      'Na pomůcce lze také procvičovat pravidla hry s kostkou, dovednost střídání se a vítězství a prohru.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/66/01/ixE5vJqJ_t.jpg',
  },
  {
    id: '9',
    title: 'PANÁČCI',
    kategory: 'logika, představivost, jemná motorika',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Oddechová hra, u které si děti procvičují zručnost, pozorovací a kombinační schopnosti. Hra obsahuje 2 panáčky, 2 panenky a spoustu oblečků.',
    tips:
      'Na pomůcce lze také procvičovat barvy, slovní zásobu a oblekání vzhledem k počasí. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/4c/4d/ajaMhbHi_t.jpg',
  },
  {
    id: '10',
    title: 'NOSÍKOVÁ',
    kategory: 'percepce, představivost',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Hra zaměřená na rozvoj jednoho z našich smyslů, konkrétně čichu. Obsahuje celkem 6 nádobek s vůněmi a 24 kartiček s obrázky. Principem hry je uhádnout čichem danou vůni a vybrat všechny odpovídající obrázky. ',
    tips:
      'Na pomůcce lze také procvičovat slovní zásobu, řeč a představivost. ',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/20/3c/1SoYcwDU_t.jpg',
  },
  {
    id: '11',
    title: 'TREFL BABY PUZZLE',
    kategory: 'logika, jemná motorika, představivost, percepce',
    age: 'od 2 let',
    playersCount: '1',
    description:
      'Jednoduché puzzle s velkými dílky. Celkem lze složit 4 obrázky různých dopravních prostředků, které jsou odstupňované počtem dílků (2,3,4,5).',
    tips:
      'Na pomůcce lze také procvičovat barvy, slovní zásobu. Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/84/ec/WYkAW3dZ_t.jpg',
  },
  {
    id: '12',
    title: 'PUZLINO CO SE HODÍ',
    kategory: 'logika, řeč, jemná motorika, percepce',
    age: 'od 2 let',
    playersCount: '1 – 4',
    description:
      'Hra podporuje logické myšlení, poznávání zvířat a předmětů. Principem hry je poskládat k sobě dvanáct tematicky související dvojic kartiček, např. pes a bouda, slepice a vajíčko či dort a dárek.',
    tips:
      'Na pomůcce lze také procvičovat slovní zásobu či postřeh. Je možné hrát i formou pexesa, kdy kartičky je potřeba rozdělit na dvě skupiny (s vykrojením a bez). Následně se otáčí vždy kartička z každé skupiny.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/18/d1/KytVg8NZ_t.jpg',
  },
  {
    id: '13',
    title: 'PEXETRIO POVOLÁNÍ',
    kategory: 'logika, řeč, společenská hra, percepce',
    age: 'od 3 let',
    playersCount: '1 – 5',
    description:
      'Didaktická a zábavná hra, která učí pojmenovat různé druhy povolání, rozeznat jejich typické oděvy, pomůcky a nástroje, které používají. Hra je podobná pexesu, jen s tím rozdílem, že se hledá vždy trojice obrázků. ',
    tips:
      'Na pomůcce lze také procvičovat slovní zásobu, porozumnění řeči či představivost. Například lze z trojice obrázků vymýšlet příběhy.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/d4/da/cdmUbj47_t.jpg',
  },
  {
    id: '14',
    title: 'LOTO – DŮM',
    kategory: 'jemná motorika, řeč, logika, společenská hra, percepce',
    age: 'od 2 let',
    playersCount: '1 – 4',
    description:
      'Hra, která zábavnou formou procvičuje pozorovací schopnosti, logické myšlení a jemnou motoriku. Učí také děti poznávat barvy, věci kolem sebe a rozšiřovat slovní zásobu. Součástí hry je 24 obrázků podle jednotlivých témat a 4 velké obrázky s tématy kuchyně, koupelny, dětského pokoje a obývacího pokoje.',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/3b/f4/c3UyFpGT_t.jpg',
  },
  {
    id: '15',
    title: 'TREFL ČÍSLA',
    kategory: 'logika, matematické dovednosti',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Vzdělávací skládačka, při které si děti dokonale osvojí svět čísel od 1 - 10. Cvičí základní matematické dovednosti, orientaci v prostoru, třídění, skládání a jednoduché součty. Je také dokonalým úvodem do světa zvířat, počtů a matematických množin.',
    tips:
      'Na pomůcce lze také procvičovat barvy, slovní zásobu, posloupnost, jemnou motoriku. Hru je možné oživit tím, že k dílkům lze přidávat vždy dané množství korálků, knoflíčků nebo kolíčků.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/6b/52/4oInRmh4_t.jpg',
  },
  {
    id: '16',
    title: 'HÁDANKY DRÁČKA FRÁČKA',
    kategory: 'logika, řeč, pozornost, společenská hra',
    age: 'od 4 let',
    playersCount: '1 – 6',
    description:
      'Hra rozvíjející slovní zásobu, logické myšlení a schopnost soustředění je vhodná kamkoliv. Na 135 kartičkách jsou veselé veršované hádanky, na druhé straně je pak odpověď ve formě obrázku. V případě správně odpovědi se dostává žeton, který se dává na hrací plochu.',
    tips:
      'Hru je možné hrát i bez žetonů. Skvěle podporuje rozvoj porozumnění, řeč a soustředění. ',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/11/fb/WbLo8kww_t.jpg',
  },
  {
    id: '17',
    title: 'ELEKTRICKÝ VLÁČEK MAŠINKA TOMÁŠ',
    kategory: 'jemná motorika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Oddechová hra, kdy je zapotřebí postavit koleje, po kterých následně bude jezdit mašinka Tomáš. ',
    tips: 'Při hře je možné tvořit řadu příběhů.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/b4/17/8i2j4NQs_t.jpg',
  },
  {
    id: '18',
    title: 'HLADOVÍ HROŠÍCI',
    kategory: 'jemná motorika, pozornost, společenská hra',
    age: 'od 4 let',
    playersCount: '2 – 4',
    description:
      'Veselá hra jejímž cílem je se svým hrochem spořádat co možná největší množství kuliček.',
    tips:
      'Hrou je možné rozvíjet počítání, jemnou motoriku, střídání se, zvládání prohry a výhry. Například je možné kuličky do žlábku dávat různými prsty (palec a ukazováček, palec a prostředníček aj.) či hrošíka mačkat různou částí těla (ruka, loket, noha, čelo aj.)',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/4c/3d/gZauo5hc_t.jpg',
  },
  {
    id: '19',
    title: 'JANOD ZATLOUKACÍ PÍSMENA',
    kategory: 'jemná motorika, řeč, logika, písmena ',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra s písmenky, která procvičuje nejen samotná písmenka, čtení či nápodobu, ale také jemnou motoriku. Součástí je korková deska, dřevěná abeceda, hřebíčky a kladívko. Do desky se pomocí hřebíčku zatloukají libovolná slova nebo slova podle předlohy. Následně se vše dá zase vyndat a skládat další slova.',
    tips:
      'Je možné nechat dítě slovo skládat jen na základě sluchu – slovo můžeme říct celé či jej hláskujeme. Další možností je slovo napsat na papír a nechat dítě najít správná písmena a zatlouct do korkové desky. Je také možné rozvíjet komunikaci, kdy si dítě říká o hřebíček či kladívko.',
    price: '250',
    count: '2',
    img: 'https://thumbs2.imgbox.com/6a/77/ALUUzy3J_t.jpg',
  },
  {
    id: '20',
    title: 'BALANČNÍ DRÁHA PRO MÍČEK',
    kategory: 'jemná motorika, hrubá motorika, koordinace',
    age: 'od 18měsíců',
    playersCount: '1',
    description:
      'Dřevěná dráha která se skládá z 16 kusů. Prvně je potřeba sestavit dráhu a následně se balancuje s míčkem po trati ze startu až do cíle. Pomůcka procvičuje jemnou a hrubou motoriku, koordinaci oko-ruka a představivost při stavění dráhy.',
    tips:
      'Pomůcka může také rozvíjet prostorovou orientaci, barvy a schopnost spolupráce a domlouvání se.',
    price: '400',
    count: '1',
    img: 'https://thumbs2.imgbox.com/24/ea/vt62MeTQ_t.jpg',
  },
  {
    id: '21',
    title: 'AUTÍČKA A GARÁŽE',
    kategory: 'barvy, logika, jemná motorika, hrubá motorika, řeč, percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra s autíčky a garážemi, jež procvičuje mnoho oblastí. Čísla od 1 – 7, velikost a barvy. Hra rozvíjí nejen matematické dovednosti, logiku, osvojení barev, ale také jemnou a hrubou motoriku.',
    tips:
      'Oblíbená hra, se kterou se dá vymyslet prakticky cokoliv. Pomůcka může také rozvíjet prostorovou orientaci či komunikaci. Je také možné přidávat ke garážím dané množství kolíčků, korálků či knoflíků.',
    price: '200',
    count: '2',
    img: 'https://thumbs2.imgbox.com/e1/6b/3DpfAuCd_t.jpg',
  },
  {
    id: '22',
    title: 'CHYTÁNÍ ČERVA',
    kategory: 'jemná motorika, barvy, společenská hra, hra s kostkou',
    age: 'od 5 let',
    playersCount: '2 – 4',
    description:
      'Hra jež obsahuje čtvery barevné rybky (kleště), mnoho  rozlišných červíků, magnetickou desku a dřevěné desky na červíky. Principem hry je posbírat všechny červíky své barvy z magnetické desky do dřevěné desky. Každý je jinak dlouhý a tlustý, proto se každý kleštěmi bere úplně jinak – některý jde lehce a některý potřebuje značnou míru soustředění se. Hra s kostkou, kdy lze hrát jen za předpokladu, že nám padne naše barva.',
    tips:
      'Na pomůcce lze také procvičovat barvy, velikost, soustředění se, střídání se či prohru a výhru.',
    price: '400',
    count: '1',
    img: 'https://thumbs2.imgbox.com/b7/07/ma3djDvo_t.jpg',
  },
  {
    id: '23',
    title: 'KOLÍČKY S ČÍSLY',
    kategory: 'jemná motorika, barvy, matematické dovednosti',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra, při které si děti dokonale osvojí svět čísel od 1 - 6. Cvičí základní matematické dovednosti, množství, barvy a také jemnou motoriku.',
    tips:
      'Hru je možné různě variovat – hrát u stolu, na zemi nebo propojit s pohybem. Kolíčky lze připnout na polštář či závěs, nosit je v kyblíčku nebo jen v ruce. ',
    price: '100',
    count: '2',
    img: 'https://thumbs2.imgbox.com/cf/89/dQmcwxuG_t.jpg',
  },
  {
    id: '24',
    title: 'DRÁHA S AUTÍČKY',
    kategory: 'jemná motorika, barvy, oddechové ',
    age: 'od 3 let',
    playersCount: '1',
    description: 'Oddechová hra pouštění autíček po dráze.',
    tips:
      'Na pomůcce lze procvičovat barvy, komunikaci, počty, pozornost. Formou hry skořápky může dítě „soutěžit“ o autíčko. Také se dá procvičovat prostorová orientace či porozumění, např.“Podej červené autíčko pod stolem.“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/00/04/VCgHy5Bc_t.jpg',
  },
  {
    id: '25',
    title: 'DŘEVĚNÁ ZVONKOHRA',
    kategory: 'jemná motorika, percepce, oddechové ',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Oddechová hra kdy se při pouštění skleněné kuličky ozývají zvuky zvonkohry.',
    tips:
      'Pomůcku lze různě variovat. Skleněnou kuličku můžeme schovat do plastového vajíčka a dítě musí uhodnout na základě sluchu kde se ukrývá. Můžeme s kuličkou hrát hru na princip skořápek a cvičit tak pozornost. Také je možné procvičovat jemnou motoriku, kdy kuličku bereme a pouštímě různými prsty (palec a ukazováček, palec a prostředníček aj.)',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/9d/7a/SfBXjC32_t.jpg',
  },
  {
    id: '26',
    title: 'PARKING',
    kategory: 'jemná motorika, matematické dovednosti, oddechové ',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Oddechová hra na osvojení si čísel od 1 – 10. Rozvíjí se základní matematické dovednosti, posloupnost čísel, barvy a jemná motorika. ',
    tips:
      'Hru je možné různě variovat – hrát u stolu, na zemi nebo propojit s pohybem. Autíčka lze různě poschovávat a trénovat tak porozumění či prostorovou orientaci. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/33/90/cCLZuyb0_t.jpg',
  },
  {
    id: '27',
    title: 'NAKRM PEJSKY',
    kategory:
      'postřeh, jemná motorika, barvy, matematické dovednosti, společenská hra',
    age: 'od 3 let',
    playersCount: '2 – 4',
    description:
      'Jakmile miska přeteče, kuličky se rozkutálí do všech stran a hráči je musí posbírat do mističek svých pejsků. Lze hrát volně či podle daných jídelních seznamů. Hra rozvijí jemnou motoriku, postřeh, barvy a matematické dovednosti.',
    tips: 'Při hře lze trénovat také porozumnění a prohru, výhru.',
    price: '150',
    count: '2',
    img: 'https://thumbs2.imgbox.com/6f/38/ETJRxmkD_t.jpg',
  },
  {
    id: '28',
    title: 'MOJI PŘÁTELÉ Z FARMY',
    kategory: 'jemná motorika, představivost, koordinace, percepce',
    age: 'od 18měsíců',
    playersCount: '1',
    description:
      'Hra na seznámení se zvířaty na farmě a v jejím okolí. Pomůcka rozvíjí jemnou motoriku, zrakové vnímání, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ac/53/pXoW74ZQ_t.jpg',
  },
  {
    id: '29',
    title: '3 NÁKLAĎÁKY',
    kategory: 'Logika, představivost, barvy, tvary, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hlavolamová hra jejímž cílem je naložit náklad do kamionu tak, aby nikde nekoukal. Součástí jsou tři barevné náklaďáky, 10 různých dílků a 48 rébusů odstupňovaných dle náročnosti. Hra rozvíjí logické myšlení, motoriku a prostorovou představivost. ',
    tips: 'Na pomůcce lze také procvičovat barvy, tvary, pozice.',
    price: '300',
    count: '1',
    img: 'https://thumbs2.imgbox.com/5a/c7/tRdscN2z_t.jpg',
  },
  {
    id: '30',
    title: 'KOLA ZÁBAVY',
    kategory: 'jemná motorika, představivost, koordinace, percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra na seznámení se základními místnostmi a vybavením domu. Pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ff/ae/7Gy2AcNn_t.jpg',
  },
  {
    id: '31',
    title: 'VLAK S TVARY',
    kategory: 'jemná motorika, představivost, koordinace, percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra na seznámení se základními místnostmi a vybavením domu. Pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/73/0b/EqarAZtH_t.jpg',
  },
  {
    id: '32',
    title: 'V NAŠEM DOMĚ',
    kategory: 'jemná motorika, představivost, koordinace, percepce, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra na seznámení se základními místnostmi a vybavením domu. Pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '2',
    img: 'https://thumbs2.imgbox.com/56/51/0U1fkrkI_t.jpg',
  },
  {
    id: '33',
    title: 'TVARY A BARVY',
    kategory:
      'jemná motorika, představivost, koordinace, percepce, barvy, tvary',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra na seznámení se základními tvary a barvami. Široká škála tvarových a barevných kombinací, které lze jen pojmenovávat nebo je doplňovat na hrací desku do obrázků. Pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '2',
    img: 'https://thumbs2.imgbox.com/62/be/DhnjubXs_t.jpg',
  },
  {
    id: '34',
    title: 'POJĎME VYRŮST',
    kategory: 'jemná motorika, představivost, koordinace, percepce, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra na seznámení s částmi těla. Pomůcka názorně ukazuje, jak se člověk vyvíjí – od dívky po ženy/od chlapce po muže. Edukativní pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/fc/f2/qzGUSOyS_t.jpg',
  },
  {
    id: '35',
    title: 'MÉ PRVNÍ HODINY',
    kategory: 'jemná motorika, představivost, koordinace, percepce, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra která snadným způsobem představuje hodiny a části dne. Součástí jsou nadrozměrné hodiny, které napomáhají rozvoji porozumění číslům, veličinám a pojetí času. Edukativní pomůcka rozvíjí jemnou motoriku, zrakové vnímání, představivost, koordinaci oko-ruka.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/74/ec/KBAFgIhJ_t.jpg',
  },
  {
    id: '36',
    title: 'SLOVNÍ EXPRES',
    kategory: 'společenská hra, řeč, logika, písmena',
    age: 'od 8 let',
    playersCount: '2 – 4',
    description:
      'Slovní hra jejíž součástí je 55 karet s písmeny a zvoneček. Principem hry je složit slovo z karat které hráč drží v ruce a karty vyložené z balíčku. Hra rozvíjí slovní zásobu, pozornost, logiku. ',
    tips:
      'Je možné nechat dítě slovo skládat jen na základě sluchu – slovo můžeme říct celé či jej hláskujeme. Další možností je slovo napsat na papír a nechat dítě najít správná písmena a slovo složit.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/a0/e1/YJh1gcIi_t.jpg',
  },
  {
    id: '37',
    title: 'PÍSMENKO K PÍSMENKU 2',
    kategory: 'řeč, logika, představivost, písmena',
    age: 'od 5 let',
    playersCount: '1 – 4',
    description:
      'Zábavná naučná hra, která obsahuje návod na 2 varianty pro menší a větší děti. Princip hry spočívá ve vybrání obrázku a naskládání písmen do slova, které koresponduje s obrázkem. Hra rozvíjí logiku, představivost, osvojuje dovednost čtení a psaní.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/57/e5/xllOFFec_t.jpg',
  },
  {
    id: '38',
    title: 'PŘEČTU TI, MAMI',
    kategory: 'řeč, logika, představivost, písmena',
    age: 'od 6 let',
    playersCount: '1 – 4',
    description:
      'Naučná hra, která dětem zábavným a napínavým způsobem usnadní osvojování si čtení. Principem hry je z dílků složit obrázky se slovem. Každá hláska má svůj dílek, který představuje část z celého obrázku. Hra rozvíjí logiku, představivost, osvojuje dovednost čtení a psaní.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/2d/f5/T776M0E9_t.jpg',
  },
  {
    id: '39',
    title: 'PÍSMENKO K PÍSMENKU',
    kategory: 'řeč, logika, představivost, písmena',
    age: 'od 5 let',
    playersCount: '1 – 4',
    description:
      'Zábavná naučná hra, která obsahuje návod na 2 varianty pro menší a větší děti. Princip hry spočívá ve vybrání obrázku a naskládání písmen do slova, které koresponduje s obrázkem. Hra rozvíjí logiku, představivost, osvojuje dovednost čtení a psaní.',
    tips:
      'Při hře lze trénovat také porozumnění, slovní zásobu, prostorovou orientaci. Pomůcku lze využít u stolečku, na zemi nebo propojit s pohybem.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/aa/f7/LTI8np33_t.jpg',
  },
  {
    id: '40',
    title: 'NAVLÉKEJ,NEČEKEJ',
    kategory: 'jemná motorika, barvy, koordinace, představivost, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra jež od dětí rozvíjí zručnost, znalost barev a paměť. Principem hry je zapamatovat si řadu barevných korálů dle předlohy a následně je ve stejném pořadí navléct na šňůrku. Hra obsahuje celkem 55 karet s různě náročnými vzory. Rozvíjí paměť, jemnou motoriku, koordinaci, barvy.',
    tips:
      'Hru je možné hrát i s přiznanou předlohou, kdy dítě navléká na základě obrázku na stole. Na hře lze procvičovat také barvy či matematické dovednosti.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/18/72/dHHaCDZn_t.jpg',
  },
  {
    id: '41',
    title: 'VYSLOV SLOVA',
    kategory: 'řeč, písmena',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Logopedická hra, která má za cíl rozvoj řeči. Pomáhá s vyslovováním písmen S,C,Z,Š,Č,Ž pomocí 96 obrázků. Během hry se kromě učení vyslovování problematických hlásek cvičí také postřeh, vnímání, myšlení a pozornost.',
    tips: 'Na hře lze také procvičovat slovní zásobu.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/e3/74/PIyWpaGC_t.jpg',
  },
  {
    id: '42',
    title: 'PEXESO PRO UŠI',
    kategory: 'percepce, logika, postřeh, koordinace',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Zvuková hra, která obsahuje 16 zvukových krychliček. Principem hry je to, aby dítě rozpoznalo zvuk stejných krychliček.Hra je primárně mířená na rozvoj sluchové percepce.',
    tips:
      'Lze na této pomůcce procvičovat také barvy, schopnost střídat se, pozornost či prostorovou orientaci.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ca/ad/AoHTKlr3_t.jpg',
  },
  {
    id: '43',
    title: 'POVÍM TI, MAMI',
    kategory: 'řeč, logika, představivost, písmena',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Didaktická hra, která obsahuje 40 obrázků, na nichž je potřeba sestavit 10 logických příběhů. Princip spočívá ve správném sestavení karet tak, aby vznikl logický příběh. Tato hra učí dítě chápat časové posloupnosti a prohlubovat logické myšlení. Cílí také na rozvoj vyjadřovacích schopností, rozvíjení představivosti či postřeh.',
    tips:
      'Lze na této pomůcce procvičovat také slovní zásobu či pracovat s emocemi. U jednotlivých příběhů je možné bavit se o pocitech, jaké daná osoba mohla v danou chvíli mít.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ae/84/oFQMH4L8_t.jpg',
  },
  {
    id: '44',
    title: 'POVÍM TI, MAMI 2',
    kategory: 'řeč, logika, představivost, písmena',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Didaktická hra, která obsahuje 40 obrázků, na nichž je potřeba sestavit 10 logických příběhů. Princip spočívá ve správném sestavení karet tak, aby vznikl logický příběh. Tato hra učí dítě chápat časové posloupnosti a prohlubovat logické myšlení. Cílí také na rozvoj vyjadřovacích schopností, rozvíjení představivosti či postřeh.',
    tips:
      'Lze na této pomůcce procvičovat také slovní zásobu či pracovat s emocemi. U jednotlivých příběhů je možné bavit se o pocitech, jaké daná osoba mohla v danou chvíli mít.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/5f/83/wLkNc8Lc_t.jpg',
  },
  {
    id: '45',
    title: 'TVARY,BARVY,PAMĚŤ',
    kategory: 'tvary, barvy, logika, jemná motorika',
    age: 'od 4 let',
    playersCount: '2 – 4',
    description:
      'Didaktická a zábavná hra, která učí barvy a tvary, rozvíjí pamět, postřeh a koncentraci. Součástí je 55 karet se vzory. Dítě si vybere jednu kartu, pokusí se ji zapamatovat a následně na provázek navlékne barevné tvary ve stejném množství a pořadí. Hra procvičuje jemnou motoriku, paměť, postřeh, koordinaci oko-ruka.',
    tips:
      'Hru je možné hrá i jako otevřenou. Dítě má kartu před sebou a podle ní navlíká. Je skvělá také pro procvičování matematických dovedností či porozumění instrukcím.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/90/dd/caL8uONc_t.jpg',
  },
  {
    id: '46',
    title: 'PAMĚŤ 3D',
    kategory: 'logika, pozornost, představivost, jemná motorika',
    age: 'od 5 let',
    playersCount: '1 – 5',
    description:
      'Naučná hra při které je potřeba poskládat barevné kostky podle jednoho ze vzorů. Hra má celkem 55 karet, na nichž jsou v různých sestavách vyobrazeny barevné kostky. Hra cvičí postřeh, paměť a prostorovou představivost.',
    tips:
      'Na pomůcce lze také procvičovat barvy. Hru lze různě variovat – na zemi, u stolečku, propojit s pohybem.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/0e/88/BVStQsKf_t.jpg',
  },
  {
    id: '47',
    title: 'PÍSMENKOBRANÍ',
    kategory: 'řeč, logika, písmena, jemná motorika, hra s kostkou',
    age: 'od 7 let',
    playersCount: '2 – 3',
    description:
      'Hra zaměřená na trénink myšlení a rozšíření slovní zásoby.Slova se skládají z pěti vylosovaných písmen. Počet hlásek určí číslo, které padne na kostce.',
    tips:
      'Je možné nechat dítě slovo skládat jen na základě sluchu – slovo můžeme říct celé či jej hláskujeme. Další možností je slovo napsat na papír a nechat dítě najít správná písmena a slovo složit.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/c4/0d/QKJYio5o_t.jpg',
  },
  {
    id: '48',
    title: 'PSANÍ A MAZÁNÍ – ČÍSLA',
    kategory:
      'logika, koordinace, čísla, grafomotorika, matematické dovednosti',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Vzdělávací hra s jednoduchými početními kvízy, která děti kreslením po předloze naučí správné tvary čísel, jejich poznávání a úchop tužky. Hra trénuje pořítání, postřeh a manuální zrušnost. Zadní strany 28 kartiček obsahují jednoduché kvízy pro procvičení práce s čísly.',
    tips:
      'Při této hře je možné trénovat také množství, například přidáváním knoflíků či kolíků ve správném množství. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/87/88/QraLeGaP_t.jpg',
  },
  {
    id: '49',
    title: 'PSANÍ A MAZÁNÍ – PÍSMENKA',
    kategory: 'logika, koordinace, písmena, grafomotorika',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Vzdělávací hra, která děti kreslením po předloze učí správné tvary písmen, u kterých se nacházejí obrázky, což velmi napomáhá při osvojování si čtení. Současně zdokonaluje zručnost a úchop pera. Hra se skládá z 28 naučných kartiček, na kterých se nachází vždy jedno z písmen abecedy. ',
    tips:
      'Při této hře je možné trénovat také řeč a slovní zásobu. K jednotlivému písmenku abecedy lze vymýšlet slova, která na ně začínají apod. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/08/03/rasaqBMh_t.jpg',
  },
  {
    id: '50',
    title: 'DOTTY DINOSAURS',
    kategory: 'barvy, tvary, hra s kostkou',
    age: 'od 3 let',
    playersCount: '2 – 4',
    description:
      'Hra s kostkou jejímž cílem je co nejdříve nasbírat všechny tvary určité barvy na svého dinosaura.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/14/05/vdBY7uWm_t.jpg',
  },
  {
    id: '51',
    title: 'COLORETO',
    kategory: 'logika, barvy, společenská hra',
    age: 'od 8 let',
    playersCount: '3 – 5',
    description:
      'Karetní společenská hra. Každý hráč sbírá tři různé barvy chameleonu. Na konci hry získává za získané chameleony kladné nebo záporné body. Hra procvičuje pozornost, logiku a barvy.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/a2/ee/yPT6M3Lu_t.jpg',
  },
  {
    id: '52',
    title: 'FAZOLE',
    kategory: 'logika, matematické dovednosti, společenská hra',
    age: 'od 10 let',
    playersCount: '3 – 5',
    description:
      'Karetní společenská hra. Každý hráč má své fazolové pole, kde pěstuje různé druhy fazolí. Pravidelně musí sázet či sklízet. S ostatními hráči může obchodovat a získat tak více fazolarů. Cílem hry je mít co nejvíce vydělaných fazolarů. Hra rozvíjí strategické myšlení, matematické dovednosti, pozornost a postřeh.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/7d/25/vHg9KDnz_t.jpg',
  },
  {
    id: '53',
    title: 'SAFARI',
    kategory: 'hra s kostkou, společenská hra, logika',
    age: 'od 4 let',
    playersCount: '2 – 4',
    description:
      'Hra rozvíjí paměť a strategické myšlení. Po herní ploše je rozmístěno 24 zvířátek. Cílem hry je nasbírat co nejdříve své album zvířátek.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/34/d7/daGu4w0h_t.jpg',
  },
  {
    id: '54',
    title: 'BONBONY',
    kategory: 'hra s kostkou, společenská hra, barvy',
    age: 'od 3 let',
    playersCount: '2 – 4',
    description:
      'Hra s kostkou, která trénuje pozornost a barvy. K dispozici jsou dvě úrovně – dvoubarevné a tříbarevné dřevěné bonbony. Po hodu kostkou je vždy potřeba najít bonbon, který odpovídá barvám na kostkách. ',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '400',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ab/91/DvKo9x3x_t.jpg',
  },
  {
    id: '55',
    title: 'DJECO DOMINO 123 ',
    kategory: 'společenská hra, logika, matematické dovednosti',
    age: 'od 4 let',
    playersCount: '1 a více',
    description:
      'Didaktické domino je zaměřeno na výuku základních početních dovedností. Hráči k sobě přiřazují číslo a obrázek s odpovídajícím počtem zvířátek. Hra má celkem 28 kartiček.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost, prohru, výhru či zvířata.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/6b/03/Gd3Izc74_t.jpg',
  },
  {
    id: '56',
    title: 'DIXIT',
    kategory: 'logika, společenská hra, představivost',
    age: 'od 8 let',
    playersCount: '1 a více',
    description:
      'Společenská hra rozvíjející představivost, fantazii a řeč. Principem hry je na základě popisu jednoho z hráčů uhodnout o jakou z vyložených karet se jedná.',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/84/b6/wqCPOqu2_t.jpg',
  },
  {
    id: '57',
    title: 'OVOCNÁ VĚŽ',
    kategory: 'společenská hra, hra s kostkou, jemná motorika',
    age: 'od 3 let',
    playersCount: '2 – 6',
    description:
      'Hra zdokonalující zručnost, strategické myšlení, trpělivost, soustředění, poznávání barev a druhů ovoce. Kostky ve tvaru ovoce se umisťují na dřevěné hrací karty, které se při naplnění vrství na sebe. ',
    tips:
      'Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/bc/88/dXGIBJTt_t.jpg',
  },
  {
    id: '58',
    title: 'TANGRAMY',
    kategory: 'logika, představivost, tvary, barvy',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Hra při které dle předlohy dítě skládá některý z obrazců, který je složený z různých tvarů. Podílí se na rozvoji představivosti, koordinace, logiky a trpělivosti.',
    tips:
      'Při této hře je možné trénovat také barvy, tvary a vlastní fantazii. Děti mohou složit svůj vlastní obrazec.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/f4/a1/zNfiNniU_t.jpg',
  },
  {
    id: '59',
    title: 'TŘÍDENÍ BAREV',
    kategory: 'společenská hra, hra s kostkou, barvy',
    age: 'od 3 let',
    playersCount: '2 a více',
    description:
      'Jednoduchá hra s kostkou na osvojení a procvičení si základních barev. Na základě barvy, která padne na kostce hráč vybírá stejný obrázek dané barvy a přiřazuje jej na herní plochu. ',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, střídání se, trpělivost a prohru, výhru.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/18/00/Y2ihx7Wb_t.jpg',
  },
  {
    id: '60',
    title: 'TŘÍDENÍ BAREVNÝCH TVARŮ',
    kategory: 'společenská hra, hra s kostkou, barvy, tvary',
    age: 'od 3 let',
    playersCount: '2 a více',
    description:
      'Jednoduchá hra s kostkou na osvojení a procvičení si základních barev a tvarů. Na základě barvy, která  padne na kostce hráč vybírá tvar dané barvy a přiřazuje jej na herní plochu. ',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, střídání se, trpělivost a prohru, výhru.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/96/c6/dLOdMAQT_t.jpg',
  },
  {
    id: '61',
    title: 'POVÍDAČKY NA CESTY',
    kategory: 'společenská hra, logika, představivost, řeč',
    age: 'od 5 let',
    playersCount: '2',
    description:
      'Hra podporující vzájemnou komunikaci, pozornost, paměť, fantazii, poznávání světa kolem nás, emoční inteligenci, logické a matematické uvažování.',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, střídání se, trpělivost a prohru, výhru.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/23/cf/I2eTHaQj_t.jpg',
  },
  {
    id: '62',
    title: 'VKLÁDÁNÍ KLÍČŮ',
    kategory: 'logika, jemná motorika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Jednoduchá dřevěná vkládačka, kdy je potřeba každý z klíčů vložit do správné podložky. Procvičuje koordinaci, motoriku, představivost, trpělivost.',
    tips:
      'Hra je možná různě variovat, klíče je možné schovávat na různá místa a následně dítě navigovat. Lze trénovat i prostorovou orientaci, následování slovní instrukce aj.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/c7/e8/7ZlbPAPu_t.jpg',
  },
  {
    id: '63',
    title: 'PINZETY ',
    kategory: 'jemná motorika, koordinace',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Aktivita podporující rozvoj jemné motoriky a úchop pinzety. Cvičí také trpělivost či koordinaci.',
    tips:
      'Hru je možné rozšířit o matematické dovednosti, umisťování kostiček do mističek či na místa dle slovní instrukce.',
    price: '100',
    count: '3',
    img: 'https://thumbs2.imgbox.com/1a/08/4X4gQG0G_t.jpg',
  },
  {
    id: '64',
    title: 'ŠROUBKY A MATIČKY',
    kategory: 'jemná motorika, barvy, tvary, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka zaměřená primárně na procvičení jemné motoriky. Princip hry je sundání či navrácení barevné matičky na příslušný šroubek. Matičky dítě přiděluje na základě barvy a tvaru k příslušnému šroubku.',
    tips:
      'Hra je možná různě variovat, šroubky či matičky je možné schovávat na různá místa a následně dítě navigovat. Lze trénovat i prostorovou orientaci, následování slovní instrukce aj.',
    price: '100',
    count: '3',
    img: 'https://thumbs2.imgbox.com/f7/9c/M5BQ0inR_t.jpg',
  },
  {
    id: '65',
    title: 'HMATOVÝ PYTLÍK',
    kategory: 'jemná motorika, logika, řeč, představivost, percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'V této hře jde o to rozeznat předměty, které se ukrývají uvnitř pytlíku a to jen na základě hmatu. Je možné předmět podle hmatu najít a přiřadit k obrázku nebo naopad – podle obrázku najít ve hmatovém pytlíku příslušný předmět.',
    tips: 'Při této hře je možné trénovat také slovní zásobu.',
    price: '100',
    count: '3',
    img: 'https://thumbs2.imgbox.com/cf/9a/e1FDQmku_t.jpg',
  },
  {
    id: '66',
    title: 'NAVLÉKÁNÍ KORÁLŮ – VELKÉ',
    kategory: 'jemná motorika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Navlékání korálků posiluje a rozvíjí nejen jemnou motoriku, ale také koordinaci oko-ruka či zrakovou percepci. ',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, prostorovou orientaci, následování slovní instrukce aj.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/8f/a7/jLm82p4z_t.jpg',
  },
  {
    id: '67',
    title: 'NAVLÉKÁNÍ KORÁLŮ – PŮLENÉ OVOCE',
    kategory: 'jemná motorika, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Navlékání korálků posiluje a rozvíjí nejen jemnou motoriku, ale také koordinaci oko-ruka či zrakovou percepci. Při navlékání těchto korál musí dítě najít sprváné dílky k sobě a ve správném pořadí je navléct, aby vytvořil jeden z druhů ovoce či zeleniny.',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, prostorovou orientaci, následování slovní instrukce aj.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/e9/28/l9MtaIyd_t.jpg',
  },
  {
    id: '68',
    title: 'NAVLÉKÁNÍ KORÁLŮ - PÍSMENA',
    kategory: 'jemná motorika, koordinace, logika, písmena',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Navlékání korálků posiluje a rozvíjí nejen jemnou motoriku, ale také koordinaci oko-ruka či zrakovou percepci. Při navlékání těchto korál dítě skládá slova buď na základě napsané předlohy nebo na základě sluchu. ',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, prostorovou orientaci, následování slovní instrukce aj.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/1c/b5/yXUnUpoP_t.jpg',
  },
  {
    id: '69',
    title: 'LEGO KOSTKY S PŘEDLOHOU',
    kategory: 'logika, představivost, barvy, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Tato pomůcka umožňuje dvě aktivity. Je možné přikládat stejně barevné kostky na plánek dle barev. Při této aktivitě se osvojují a procvičují barvy. Druhou možností je stavět kostky podle plánku. Celkově dochází k rozvoji představivosti, koordinace oko-ruka, procvičení barev či množství.',
    tips:
      'Při této hře je možné trénovat  prostorovou orientaci, následování slovní instrukce aj.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/d5/dc/QF5A8Hmr_t.jpg',
  },
  {
    id: '70',
    title: 'ELEKTRICKÉ RYBIČKY',
    kategory: 'barvy, jemná motorika, pozornost',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Hra spočívá v chytání točících se rybiček na prut. Procvičuje jemnou motoriku, koordinaci, postřeh. Lze procvičovat barvy.',
    tips:
      'Při této hře je možné trénovat také matematické dovednosti, komunikaci, střídání se, trpělivost a prohru, výhru.',
    price: '250',
    count: '2',
    img: 'https://thumbs2.imgbox.com/1c/84/MuUmrbiI_t.jpg',
  },
  {
    id: '71',
    title: 'CÉČKA',
    kategory: 'barvy, jemná motorika, představivost, logika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra jež rozvíjí jemnou motoriku, koordinaci oko-ruka, trpělivost, představivost a fantazii. Barevná céčka lze spojovat na základě předlohy či vlastní fantazie. Předlohy jsou od jednoduchých až po složitější.',
    tips:
      'Při této hře je možné trénovat matematické dovednosti, barvy, prostorovou orientaci aj.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/8d/99/iO1PCsiO_t.jpg',
  },
  {
    id: '72',
    title: 'NAVLÉKÁNÍ – ČÍSELNÉ RUCE',
    kategory: 'jemná motorika, barvy, matematické dovednosti, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra posilující koordinaci oko-ruka, poznávací a zrakové vnímání. Lze navlékat podle barevné předlohy či vlastní fantazie. Druhou možností je z ruk navlékat základní číselné příklady.',
    tips:
      'Při této hře je možné trénovat matematické dovednosti, barvy, prostorovou orientaci aj.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/3b/59/ieDP6OK4_t.jpg',
  },
  {
    id: '73',
    title: 'CHYTÁNÍ RYBIČEK – BAREVNÉ',
    kategory: 'barvy, jemná motorika, hrubá motorika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka zaměřená primárně na procvičení jemné motoriky, koordinace a barev. ',
    tips:
      'Pomůcku lze různě variovat. Barevné rybičky se mohou chytat na základě řečené barvy či hádanky o barvě. Mohou se umisťovat na různé místa (prostorová orientace) nebo například do nádob stejné barvy.',
    price: '150',
    count: '2',
    img: 'https://thumbs2.imgbox.com/6c/54/OocmaV6a_t.jpg',
  },
  {
    id: '74',
    title: 'CHYTÁNÍ RYBIČEK - DŘEVĚNÉ',
    kategory: 'barvy, jemná motorika, hrubá motorika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka zaměřená primárně na procvičení jemné motoriky, koordinace a barev. ',
    tips:
      'Pomůcku lze různě variovat. Barevné rybičky se mohou chytat na základě řečené barvy či hádanky o barvě. Mohou se umisťovat na různé místa (prostorová orientace) nebo například do nádob stejné barvy.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/5d/54/U87aXmyu_t.jpg',
  },
  {
    id: '75',
    title: 'KULIČKO HOP',
    kategory: 'jemná motorika, koordinace, matematické dovednosti',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Principem hry je dostat kuličku do otvoru s co možná nejvyšším číslem. Hra pracuje hodně se soustředěním, koordinací pohybů a trpělivostí.',
    tips:
      'Při této hře je možné trénovat také matematické dovednosti, střídání se, trpělivost a prohru, výhru.',
    price: '150',
    count: '2',
    img: 'https://thumbs2.imgbox.com/d1/a5/X1swDmvk_t.jpg',
  },
  {
    id: '76',
    title: 'KRMÍCÍ RYBIČKY',
    kategory: 'jemná motorika, hrubá motorika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Multifunkční pomůcka. Rybky se dají krmit – schovat do nich obrázek či dílek jiné pomůcky. Skvěle rozvíjí jemnou motoriku, koordinaci, trpělivost.',
    tips: 'Oblíbená pomůcka, která je vhodnou kombinací k dalším hrám.',
    price: '500',
    count: '1',
    img: 'https://thumbs2.imgbox.com/77/7a/nxryAtX0_t.jpg',
  },
  {
    id: '77',
    title: 'FERDA A JEHO MOUCHY',
    kategory: 'emoce, logika, představivost',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Žabák Ferda a jeho menší plyšoví kamarádi, kteří představují jednotlivé emoce – hněv, strach, radost, zvědavost, žárlivost, důvěra, smutek, osamocení. K tomu knížka s pohádkami, aktivitami a návody, jak s žabákem Ferdou pracovat a rozvíjet s dětmi jejich emoce.',
    tips: 'Skvělá pomůcka pro práci s emocemi.',
    price: '500',
    count: '1',
    img: 'https://thumbs2.imgbox.com/aa/3f/ENldIJih_t.jpg',
  },
  {
    id: '78',
    title: 'ČÍSELNÉ VÁLCE',
    kategory: 'jemná motorika, matematické dovednosti, hrubá motorika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Princip hry je postavit z válců pyramidu, kterou co možná nejmenším počtem hodů celou shodit.',
    tips:
      'Hru je skvělé propojit s pohybem. Při této hře je možné trénovat také střídání se, trpělivost a prohru, výhru.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/d0/ea/HzU8pQHW_t.jpg',
  },
  {
    id: '79',
    title: 'HRA SVAČINOVÝ BOX',
    kategory: 'společenská hra, logika, pozornost, řeč',
    age: 'od 3 let',
    playersCount: '2 – 4',
    description:
      'Úkolem hráčů je co nejrychleji do své svačinové krabičky nasbírat karty se zdravými potravinami. Hra je zaměřena na paměť a rozpoznávání a pojmenovávání potravin. ',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, střídání se, trpělivost a prohru, výhru.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/88/e6/rvBgCrtI_t.jpg',
  },
  {
    id: '80',
    title: ' TIK TAK BUM JUNIOR',
    kategory: 'řeč, pozornost, společenská hra',
    age: 'od 5 let',
    playersCount: '2 – 12',
    description:
      'Slovní hra podporující kreativitu, spontánnost a slovní zásobu. Zapne se bommba, odkryje se karta a řekne se jedno slovo, které souvisí s obrázkem na kartě – v tu chvílí se tikající bomba předává dalšímu v pořadí.',
    tips:
      'Při této hře je možné trénovat také slovní zásobu, střídání se, trpělivost a prohru, výhru.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/49/32/B50UbOeH_t.jpg',
  },
  {
    id: '81',
    title: 'ZVUKY ZVÍŘAT S CD',
    kategory: 'percepce',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Zvuková hra s CD a kartičkami zvířat. Možností je několik, lze hrát jako pexeso nebo na základě citoslovce vyhledat příslušný obrázek. ',
    tips:
      'Při této hře je možné trénovat slovní zásobu, řeč, následování slovní instrukce.',
    price: '400',
    count: '1',
    img: 'https://thumbs2.imgbox.com/26/21/wcV4oIlJ_t.jpg',
  },
  {
    id: '82',
    title: 'OBLIČEJE',
    kategory: 'jemná motorika, koordinace, logika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Zábavná hra jejíž principem je podle předlohy složit příslušný obličej. Kromě jemné motoriky dochází také k rozvoji koordinace oko-ruka, pozornosti či soustředění.',
    tips:
      'Hru lze hrát i volně – kdy dítě může skládat podle vlastní fantazie. Další možností je procvičování porozumění řeči, kdy na dítě na základě slovní instrukce bere příslušné prvky.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/bd/b5/Qg5shU3f_t.jpg',
  },
  {
    id: '83',
    title: 'DINO PUZZLE KRTEK 2X48',
    kategory: 'jemná motorika, logika, koordinace, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Puzzle s tematikou krtka, které obsahuje 2 motivy po 48dílcích. Puzzle je skvělým nástrojem pro rozvoj představivosti, logiky, ale také koordinace či jemné motoriky.',
    tips:
      'Lze aktivitu propojit s pohybem. Velmi úspěšná je například propojení s hrou „samá voda x hoří.“',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/14/99/kkA6wU0W_t.jpg',
  },
  {
    id: '84',
    title: 'DINO PUZZLE KRTEK 24',
    kategory: 'jemná motorika, logika, koordinace, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Puzzle s tematikou krtka, které obsahuje 1 motiv z 24 dílků. Puzzle je skvělým nástrojem pro rozvoj představivosti, logiky, ale také koordinace či jemné motoriky.',
    tips:
      'Lze aktivitu propojit s pohybem. Velmi úspěšná je například propojení s hrou „samá voda x hoří.“',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ce/44/kyO81kJn_t.jpg',
  },
  {
    id: '85',
    title: 'LOGO COROLINO',
    kategory: 'barvy, tvary, hra s kostkou, jemná motorika, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka zaměřená na osvojení si barev a tvarů. Hra obsahuje několik motivů, do kterých je potřeba doplnit příslušné chybějící tvary. K dispozici jsou dvě kostky – s tvary a barvami. Na základě toho, co na kostkách padně volí dítě příslušný dílek a doplňuje na chybějící místo. Skvělá pomůcka pro osvojení si barev, tvarů, základní práce s kostkou. ',
    tips:
      'Hru je možné hrá i bez kostek – buď tvary a barvy jen pojmenovávat na základě toho, jaký dílek dítě vezme. Případně trénovat porozumění řeči a následování slovní instrukce – kdy dítě volí dílek, který je mu řečen.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/3a/18/LDK3S2Oj_t.jpg',
  },
  {
    id: '86',
    title: 'MATEMATIKA',
    kategory: 'matematické dovednosti, logika',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Pomůcka zaměřená na matematické dovednosti – číselná řada, množství, sčítání a odčítání. Celkem obsahuje 4 logické hry jež se zaměřují na zmíněné oblasti. ',
    tips:
      'Hru je možné oživit tím, že k dílkům lze přidávat vždy dané množství korálků, knoflíčků nebo kolíčků. ',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/8c/eb/NaTDgsYq_t.jpg',
  },
  {
    id: '87',
    title: 'BABY LOGIC',
    kategory: 'logika, řeč, jemná motorika, percepce',
    age: 'od 1 roku',
    playersCount: '1',
    description:
      'Logická hra pro nejmenší. Principem je spojit dva dílky – matka a mláďe. Pomůcka cílí na jemnou motoriku, pozornost, orientaci ve zvířecím světě a slovní zásobu.',
    tips:
      'Možné propojit s pohybem, prostorovou orientací či zvukovým  efektem každého ze zvířete.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ce/2e/T9gqGUY9_t.jpg',
  },
  {
    id: '88',
    title: 'ČÍSLA A BARVY HELLO KITTY',
    kategory: 'čísla, barvy, matematické dovednosti, logika, jemná motorika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka která napomáhá v orientaci ve světě čísel a barev. Součástí jsou dvě aktivity – jedna na procvičování matematických dovedností, převážně číslo a množství. Druhá aktivita je přiřazení stejných barev k sobě..',
    tips:
      'Hru je možné oživit tím, že k dílkům lze přidávat vždy dané množství korálků, knoflíčků nebo kolíčků. Oblíbené je propojení se známou hrou „Čáp ztratil čepičku.“',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/29/cb/1R520gcP_t.jpg',
  },
  {
    id: '89',
    title: 'FERDA – LOGICKÉ MYŠLENÍ',
    kategory: 'logika, řeč, představivost, jemná motorika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Logické myšlení je naučná hra, která za pomoci Ferdy a jeho sympatických kamarádů dětem pomáhá rozvíjet logické a kognitivní schopnosti, stimulovat inteligenci a představivost. Děti  spojují kartičku činnosti s kartičkou věci, která je pro danou činnost potřebná.',
    tips: 'Pomůcku lze také použít pro rozvoj slovní zásoby či komunikace. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/de/37/7FQhwXqw_t.jpg',
  },
  {
    id: '90',
    title: 'FERDA - ČÍSLA',
    kategory: 'logika, jemná motorika, čísla, matematické dovednosti',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Naučná hra Čísla učí dítě zábavnou formou základy aritmetiky pomocí karet, čísel a množství. Vzdělává zábavným a jednoduchým způsobem – rozvíjí inteligenci a logické myšlení. ',
    tips:
      'Hru je možné oživit tím, že k dílkům lze přidávat vždy dané množství korálků, knoflíčků nebo kolíčků. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/98/e1/VOYTRao6_t.jpg',
  },
  {
    id: '91',
    title: 'FERDA - KDE ŽIJÍ?',
    kategory: 'logika, jemná motorika, řeč, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Hra učí zábavnou formou názvy mnoha různých zvířat a prostředí, v nichž žijí. Používáním přídavných karet a 2 kontrolních karet se  dítě učí přiřadit zvířátka nakreslená na kartách k prostředí, ve kterém žijí. ',
    tips:
      'Možné propojit s pohybem, prostorovou orientací či zvukovým  efektem každého ze zvířete.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/c3/77/F2LTb9hD_t.jpg',
  },
  {
    id: '92',
    title: 'DJECO BINGO',
    kategory: 'jemná motorika, řeč, logika, společenská hra, percepce',
    age: 'od 3 let',
    playersCount: '2  - 4',
    description:
      'Společenská hra, kdy každý z hráčů sbírá určité obrázky do své destičky. Hra je skvělým pomocníkem při učení se společenských her, střídání se a respektování pravidel.',
    tips: 'Je možné využít pro rozvoj slovní zásoby.',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/66/7f/bmyjRXGU_t.jpg',
  },
  {
    id: '93',
    title: 'DJECO OCTO DOMINO',
    kategory:
      'jemná motorika, řeč, logika, společenská hra, matematické dovednosti',
    age: 'od 2 let',
    playersCount: '2  - 4',
    description:
      'Domino které se dá hrát dvojím způsobem. Zvířecí domino pro nejmenší, kdy se k sobě připojují vždy stejná zvířátka. Nebo těžší verze s množstvím teček – zde dochází k procvičování matematických dovedností.',
    tips:
      'Při této hře je možné rozvíjet slovní zásobu a trénovat také matematické dovednosti, střídání se, trpělivost a prohru, výhru.',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/c4/8b/OSw938r3_t.jpg',
  },
  {
    id: '94',
    title: 'LEGOLINO',
    kategory: 'logika, představivost, tvary, barvy',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Hra při které dle předlohy dítě skládá některý z obrazců, který je složený z různých tvarů. Podílí se na rozvoji představivosti, koordinace, logiky a trpělivosti.',
    tips:
      'Při této hře je možné trénovat také barvy, tvary a vlastní fantazii. Děti mohou složit svůj vlastní obrazec.',
    price: '350',
    count: '1',
    img: 'https://thumbs2.imgbox.com/e0/ed/TI4kHyLG_t.jpg',
  },
  {
    id: '95',
    title: 'DJECO TACTILOTO',
    kategory: 'jemná motorika, řeč, percepce, představivost',
    age: 'od 3 let',
    playersCount: '1 – 4',
    description:
      'Hra, která zábavnou formou procvičuje schopnost pouze hmatem najít odpovídající dílek. Na ruletě se vytočí vždy jeden z možných srstí a kožíšků zvířat. Po slepu je pak potřeba najít ten stejný dílek.',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/37/cf/HATHSVhY_t.jpg',
  },
  {
    id: '96',
    title: 'TVAROVÝ LABYRINT',
    kategory: 'jemná motorika, barvy, tvary, koordinace',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Každý tvar má svůj vstup a výstup na hrací desku. Principem je, aby dítě ze startu dovedlo daný dílek ke konci a z labyrintu jej sundalo. Pomůcka cvičí koordinaci, trpělivost a také orientaci v barvách a tvarech.',
    tips:
      'Pro těžší verzi můžete doplnit slovní instrukcí „Sundej žlutý trojúhelník a dej ho na okno.“',
    price: '250',
    count: '2',
    img: 'https://thumbs2.imgbox.com/d3/f0/C3UZpWYk_t.jpg',
  },
  {
    id: '97',
    title: 'DŘEVĚNÁ VKLÁDAČKA BAREVNÁ ZVÍŘÁTKA',
    kategory: 'jemná motorika, barvy, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka se zvířátky, která zlepšuje motorické dovednosti a rozvíjí všechny smysly od hmatu až po vnímání barev. ',
    tips:
      'Propojení s pohybem či schování dílku do nádoby s hrachem nebo nějakého šuplíčku udělá ze hry úplně jiný zážitek.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/67/dc/YAYpBkd1_t.jpg',
  },
  {
    id: '98',
    title: 'DŘEVĚNÁ VKLÁDAČKA CO KOMU PATŘÍ',
    kategory: 'jemná motorika, logika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka, kdy k pěti různým zvířátům se na zákaldě předlohy přikládají věci, které k nim patří. Více typů k dispozici.',
    tips:
      'U starších dětí lze nad jednotlivýmí zvířaty diskutovat a zjišťovat proč k nim dané věci patří. ',
    price: '200',
    count: '4',
    img: 'https://thumbs2.imgbox.com/07/69/27euaDo9_t.jpg',
  },
  {
    id: '99',
    title: 'DŘEVĚNÁ KRABIČKA',
    kategory: 'jemná motorika, logika, koordinace',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná krabička s řadou předlouh – tvary, čísla, ovoce, zelenina, oblečení aj. Principem je dílek vhodit do správného odvoru v krabičce.',
    tips: 'Skvělá pomůcka na propojení s pohybem či prohloubení slovní zásoby.',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/16/60/G0k1lJVi_t.jpg',
  },
  {
    id: '100',
    title: 'DŘEVĚNÁ VKLÁDAČKA ',
    kategory: 'jemná motorika, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka zlepšující nejen motorické dovednosti, ale také představivost a logiku. Principem je docílit obrázku, který je u každého okýnka s odlišným motivem. Více typů k dispozici.',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '200',
    count: '4',
    img: 'https://thumbs2.imgbox.com/7a/d2/3z6fI8wW_t.jpg',
  },
  {
    id: '101',
    title: 'DŘEVĚNÉ PŮLENÉ OBRÁZKY',
    kategory: 'jemná motorika, logika, koordinace, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka na principu spojení dvou částí do jednoho obrázku. ',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '200',
    count: '2',
    img: 'https://thumbs2.imgbox.com/56/b9/ZzZygm6t_t.jpg',
  },
  {
    id: '102',
    title: 'DŘEVĚNÝ SLON',
    kategory: 'jemná motorika, logika, koordinace, představivost, barvy',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěný slon podporující rozvoj jemné motoriky i představivosti. Děti se učí rozpoznávat tvary, soustředit se i uvažovat. Pět barevných slonů na hraní, vkládání a skládání podle velikosti. ',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/ab/9d/yNCzKrgO_t.jpg',
  },
  {
    id: '103',
    title: 'DŘEVĚNÝ VÝVOJ ČLOVĚKA',
    kategory: 'jemná motorika, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka vývoje člověka od narození až po stáří. Lze propojit s představením jednotlivých vývojových stádií. U menších dětí lze použít jako puzzle případně skládání podle velikosti. ',
    tips:
      'Hru je možné oživit tím, že dílky budete různě schovávat a následně tak procvičovat porozumnění řeči a prostorovou orientaci, např. „Vem dílek pod židlí.“',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/6e/df/jAVPZMFu_t.jpg',
  },
  {
    id: '104',
    title: 'DŘEVENÉ LIDSKÉ TĚLO ZÁKLAD',
    kategory: 'jemná motorika, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka přibližující stavbu lidského těla – kostra, orgány a svaly. Lze propojit s bližším představením jednotlivých částí.',
    tips: 'Pomůcku je možné propojit s ukázkou částí na vlastním těle. ',
    price: '200',
    count: '2',
    img: 'https://thumbs2.imgbox.com/78/a3/yahFYRmg_t.jpg',
  },
  {
    id: '105',
    title: 'DŘEVENÉ LIDSKÉ TĚLO ROZŠÍŘENÉ',
    kategory: 'jemná motorika, koordinace, logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěná vkládačka přibližující rozšířeně stavbu lidského těla – kostra, orgány, svaly a kůže. Lze propojit s bližším představením jednotlivých částí.',
    tips: 'Pomůcku je možné propojit s ukázkou částí na vlastním těle. ',
    price: '250',
    count: '1',
    img: 'https://thumbs2.imgbox.com/97/13/OTm1Q8gO_t.jpg',
  },
  {
    id: '106',
    title: 'MATEMATICKÉ ZLOMKY',
    kategory: 'matematické dovednosti, logika',
    age: 'od 7 let',
    playersCount: '1',
    description: 'Dřevěná pomůcka k bližšímu představení zlomků.',
    tips:
      'Je možné propojit s rozdělováním určitého množství korálků či kolíčků na jednotlivé zlomky.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/05/b9/1Uit9feQ_t.jpg',
  },
  {
    id: '107',
    title: 'PUZZLE LOĎ DŘEVĚNÉ',
    kategory: 'jemná motorika, koordinace, logika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěné puzzle s velkými dílky. Principem je složit obrázek buď s nebo bez předlohy. Skvělý pomocník při osvojování si dovednosti skládání puzzlí.',
    tips:
      'Hru je možné propojit s pohybem či orientací v prostoru, kdy jednotlivé dílky dítě hledá buď na základě slovní instrukce nebo formou hry „samá voda x hoří“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/9d/4f/Wt8LuMiS_t.jpg',
  },
  {
    id: '108',
    title: 'PUZZLE AUTO DŘEVĚNÉ',
    kategory: 'jemná motorika, koordinace, logika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Dřevěné puzzle s velkými dílky. Principem je složit obrázek buď s nebo bez předlohy. Skvělý pomocník při osvojování si dovednosti skládání puzzlí.',
    tips:
      'Hru je možné propojit s pohybem či orientací v prostoru, kdy jednotlivé dílky dítě hledá buď na základě slovní instrukce nebo formou hry „samá voda x hoří“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/d4/bd/bdlRZIAJ_t.jpg',
  },
  {
    id: '109',
    title: 'PUZZLE ŠKODA',
    kategory: 'jemná motorika, koordinace, logika, představivost',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Puzzle s více dílky a oblíbenou tematikou. Principem je složit obrázek buď s nebo bez předlohy.',
    tips:
      'Hru je možné propojit s pohybem či orientací v prostoru, kdy jednotlivé dílky dítě hledá buď na základě slovní instrukce nebo formou hry „samá voda x hoří“',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/00/d1/x3sNeHv0_t.jpg',
  },
  {
    id: '110',
    title: 'PŘÍBĚH Z KOSTEK',
    kategory: 'představivost, společenská hra, logika, řeč',
    age: 'od 5 let',
    playersCount: '1 – 10',
    description:
      'Zábavná společenská hra, která rozvíjí představivost a fantazii. Součástí jsou tři kostky s různými obrázky. Z obrázků, které při hodu padnou se vytváří zajímavý a napínavý příběh. Půjčují se všechny tři dostupné najednou. Jedná se o kostky s tematikou báje, vesmír a zvířata.',
    tips:
      'Skvělá pomůcka pro rozvoj slovní zásoby, komunikace a představivosti. ',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/6f/b4/9hkFkmez_t.jpg',
  },
  {
    id: '111',
    title: 'PEXESO MOJE SMYSLY',
    kategory: 'jemná motorika, koordinace, logika, percepce, řeč',
    age: 'od 4 let',
    playersCount: '2 – 4',
    description:
      'Pexeso o lidských smyslech. Součástí jsou karty s obrázky a karty se smysli. Na zákaldě instrukce je potřeba najít vždy k sobě správné dvojice. ',
    tips:
      'Pomůcku je možné upravit i na reálné možnosti a s dítětem jednotlivé smysly vyzkoušet na vlastní kůži.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/b8/84/YaeZCrTI_t.jpg',
  },
  {
    id: '112',
    title: 'MONTESSORI KARTY – MOJE PRVNÍ 123',
    kategory: 'jemná motorika, koordinace, pozornost, čísla',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Skvělý způsob, jak si procvičit počítání a psaní číslic. K dispozici je celkem 28 karet s číslicemi, barevnými realistickými vizualizacemi a návodem, jak danou číslici nejlépe napsat – stačí jet prstem či tužkou po vyznačených čarách. Osm dalších karet skýtá zajímavé úlohy. ',
    tips:
      'Možné použít i pro stavení početních řad či přidávání množství k danému číslu. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/5a/b6/WOjPleqG_t.jpg',
  },
  {
    id: '113',
    title: 'MONTESSORI KARTY – MOJE PRVNÍ ABC',
    kategory: 'jemná motorika, koordinace, pozornost, písmena',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Skvělý způsob, jak si procvičit abecedu a psaní písmen. K dispozici je celkem 31 karet s písmenky, barevnými realistickými vizualizacemi a návodem, jak dané písmenko nejlépe napsat – stačí jet prstem či tužkou po vyznačených čarách. Další dvě karty skýtají zajímavé úlohy na procvičení abecedy. ',
    tips:
      'Použít lze i jako pomůcku k rozšiřování slovní zásoby. Vytáhněte libovolné písmeno a říkejte co možná nejvíce slov, které vás na dané písmeno napadnou.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/26/28/kt0mIZhZ_t.jpg',
  },
  {
    id: '114',
    title: ' OD NEJMENŠÍ PO NEJVĚTŠÍ',
    kategory: 'logika',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Principem hry je seřadit zvířátka od nejmenšího po to největší. Skvělá pomůcka na osvojení si rozdílů ve velikostech.',
    tips:
      'Možné propojit s pohybem, prostorovou orientací či zvukovým  efektem každého ze zvířete.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/df/26/BwYuI9EX_t.jpg',
  },
  {
    id: '115',
    title: 'PUZZLE DUO - NAJDI MĚ',
    kategory: 'logika, pozornost',
    age: 'od 2 let',
    playersCount: '1',
    description:
      'Hra obsahuje celkem 12 karet. Principem hry je spojit ty dvojice, které k sobě patří. Na jedné kartě je zvíře viditelná, druhá část karet vždy zvíře skrývá v určitém prostředí. ',
    tips:
      'Hru je možné propojit s pohybem či orientací v prostoru, kdy jednotlivé dílky dítě hledá buď na základě slovní instrukce nebo formou hry „samá voda x hoří“',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/6e/4c/mV6riQKI_t.jpg',
  },
  {
    id: '116',
    title: 'PUZZLE DUO - OPAKY',
    kategory: 'logika',
    age: 'od 2 let',
    playersCount: '1',
    description:
      'Pomůcka pro zvládnutí opaků – jako je nahoře a dole, čistý a špinavý či řada dalších. Cílem je spojit k sobě správně všech 12dílků',
    tips:
      'Hru je dobré propojit i reálnou možností – dát něco pod a na stůl aj.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/a1/94/a9c15Yzw_t.jpg',
  },
  {
    id: '117',
    title: 'PUZZLE TRIO – PŘÍBĚH',
    kategory: 'logika, řeč',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Didaktická hra, která obsahuje 8 logických příběhů. Princip spočívá ve správném sestavení karet tak, aby vznikl logický příběh. Tato hra učí dítě chápat časové posloupnosti a prohlubovat logické myšlení. Cílí také na rozvoj vyjadřovacích schopností, rozvíjení představivosti či postřeh.',
    tips:
      'Lze na této pomůcce procvičovat také slovní zásobu či pracovat s emocemi. U jednotlivých příběhů je možné bavit se o pocitech, jaké daná osoba mohla v danou chvíli mít.',
    price: '200',
    count: '1',
    img: 'https://thumbs2.imgbox.com/24/70/qsDq0VbZ_t.jpg',
  },
  {
    id: '118',
    title: 'OROMOTORICKÉ KARTY',
    kategory: 'koordinace, řeč',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Kartičky pro podporu zvládnutí základních oromotorických cviků – vypláznout jazyk, fouknout, vycenit zuby aj.',
    tips: 'Před zrcadlem je to větší švanda.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/16/a3/3Zre3awx_t.jpg',
  },
  {
    id: '119',
    title: 'LOGOPEDICKÉ HLÁSKY',
    kategory: 'koordinace, řeč',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'S touto pomůckou si zařvete jako lev nebo zahoukáte jako loď. Kartičky, které poslouží při osvojování si správné výslovosti jednotlivých hlásek. Výhodou je, že jsou doplněné o obrázky.',
    tips:
      'Před zrcadlem je to švanda, ale tepráv když se propojí s pohybem a hraním si na, to je pořádná legrace. ',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/39/ac/UdYgJxxZ_t.jpg',
  },
  {
    id: '120',
    title: 'LOGOPEDICKÉ SLABIKY',
    kategory: 'koordinace, řeč',
    age: 'od 5 let',
    playersCount: '1',
    description:
      'Spousta slabik doplněná o obrázek pro snadnější osvojení si. Skvělá pomůcka při podpoře řeči a rozvoji čtení.',
    tips:
      'Je možné vymýšlet další slova, která na danou slabiku začínají. Podporuje rozvoj řeči a slovní zásoby.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/b6/d1/o7xhJoVB_t.jpg',
  },
  {
    id: '121',
    title: 'LOGOPEDICKÉ SLOVESA',
    kategory: 'koordinace, řeč',
    age: 'od 5 let',
    playersCount: '1',
    description: 'Piktogramy pro osvojení si různých sloves.',
    tips:
      'Vyzkoušet si některá z činností a slove je jeden ze způsobů, jak si dané slovo lépe osvojit.',
    price: '100',
    count: '1',
    img: 'https://thumbs2.imgbox.com/d3/60/eLMLlweb_t.jpg',
  },
  {
    id: '122',
    title: 'BRAVO OPAKY',
    kategory: 'logika, řeč',
    age: 'od 3 let',
    playersCount: '1',
    description:
      'Pomůcka pro zvládnutí opaků – jako je nahoře a dole, čistý a špinavý či řada dalších. ',
    tips:
      'Hru je dobré propojit i reálnou možností – dát něco pod a na stůl aj.',
    price: '150',
    count: '2',
    img: 'https://thumbs2.imgbox.com/94/76/8T0UavAU_t.jpg',
  },
  {
    id: '123',
    title: 'ČÍSELNÉ PUZZLE',
    kategory: 'jemná motorika, čísla, logika, řeč',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'Hra, při které si děti dokonale osvojí svět čísel. Cvičí základní matematické dovednosti, množství a také jemnou motoriku.',
    tips:
      'Na pomůcce lze také procvičovat slovní zásobu. K jednotlivým číslům je možné přidávat i reálný počet kolíčků či knoflíčků.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/f4/d4/kLZCc508_t.jpg',
  },
  {
    id: '124',
    title: 'ABECEDA PUZZLE',
    kategory: 'jemná motorika, písmena, logika, řeč',
    age: 'od 4 let',
    playersCount: '1',
    description:
      'A jako Auto, B jako Balón a další. Celá abeceda velkých puzzlí, kdy k počátečnímu písmenu je potřeb připojit správný obrázek. Kromě slovní zásoby procvičuje i jemnou motoriku.',
    tips:
      'Hru je možné propojit s pohybem či orientací v prostoru. Pro rozvoj slovní zásoby je možné vymýšlet řadu dalších slov na dané písmeno.',
    price: '150',
    count: '1',
    img: 'https://thumbs2.imgbox.com/fd/cb/MoRTJxEu_t.jpg',
  },
];
