<template>
  <div class="container">
    <h2>{{ aid.title }}</h2>
    <div class="content">
      <div class="column-left"><img :src="aid.img" alt="" /></div>

      <div class="column-right">
        <div class="row">
          <h5>Kategorie:</h5>
          <p>{{ aid.kategory }}</p>
        </div>
        <div class="row">
          <h5>Počet hráčů:</h5>
          <p>{{ aid.playersCount }}</p>
        </div>

        <div class="row">
          <h5>Naše tipy:</h5>
          <p>{{ aid.tips }}</p>
        </div>
        <div class="row">
          <h5>Cena půjčení:</h5>
          <p>{{ aid.price }}Kč / 14 dnů</p>
        </div>
      </div>
    </div>
    <div class="description">
      <p>{{ aid.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Instructions',

  data() {
    return {};
  },
  props: ['aid'],
};
</script>

<style lang="css" scoped>
.container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  height: 120mm;
  width: 100%;
  margin: 0 auto;
  border-radius: 6mm;
  padding: 10px 20px;
  overflow: hidden;
}
h2 {
  margin: 10px;
  letter-spacing: 3px;
  font-size: 25px;
}

img {
  border-radius: 10px;
  /*margin: 10px;*/
  /*width: 280px;*/
  /*height: 90%;*/
  width: 100%;
}
.description {
  /*margin: 10px;*/
  /*height: 72px;*/
}
.column-left {
  display: flex;
  align-items: center;
  width: 45%;
  padding: 0 25px;
}

.column-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  /*width: 100%;*/
}
.column-right .row {
  margin: 5px;
}

.column-right .row h5 {
  font-size: 18px;
  margin: 2px;
}
.content {
  display: flex;
  flex-grow: 1;
}
</style>
