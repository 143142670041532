<template>
  <div
    size="A4"
    v-for="(aid, index) in aids.length / 2"
    :key="index"
    class="page"
  >
    <div class="half-page">
      <header>
        <img
          src="https://www.aut-centrum.cz/design/logo_ac.png"
          alt="logo autcentra"
        />
        <h1>AUT - Centrum o.p.s.</h1>
      </header>
      <Instructions :aid="aids[index * 2]"></Instructions>
      <footer>
        <!--         <div>
          <MySvg class="icon" shape="phone" size="23"></MySvg>
          <p>+420 730 897 041</p>
        </div> -->
        <div>
          <MySvg class="icon" shape="mail" size="23"></MySvg>
          <p>rent@aut-centrum.cz</p>
        </div>
        <div>
          <MySvg class="icon" shape="place" size="23"></MySvg>
          <p>Pod Brentovou 555/6, Praha 5</p>
        </div>
      </footer>
    </div>
    <div class="half-page">
      <header>
        <img
          src="https://www.aut-centrum.cz/design/logo_ac.png"
          alt="logo autcentra"
        />
        <h1>AUT - Centrum o.p.s.</h1>
      </header>
      <Instructions :aid="aids[index * 2 + 1]"></Instructions>
      <footer>
        <!--         <div>
          <MySvg class="icon" shape="phone" size="23"></MySvg>
          <p>+420 730 897 041</p>
        </div> -->
        <div>
          <MySvg class="icon" shape="mail" size="23"></MySvg>
          <p>rent@aut-centrum.cz</p>
        </div>
        <div>
          <MySvg class="icon" shape="place" size="23"></MySvg>
          <p>Pod Brentovou 555/6, Praha 5</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Instructions from '@/components/Instructions2.vue';
import MySvg from '@/components/MySvg.vue';
import aids from '../../data';

export default {
  name: 'InstructionGenerator',
  components: {
    Instructions,
    MySvg,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      aids,
    };
  },
};
</script>

<style scoped>
.half-page {
  padding: 0 20px;
  background: #f5b400;
}
header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
}

header img {
  /*position: absolute;*/
  width: 56px;
  /*height: 12mm;*/
  /*top: 10px;*/
  /*left: 10px;*/
  /*margin: 10mm;*/
  border-radius: 50%;
}

header h1 {
  font-family: 'RocknRoll One', sans-serif;
  text-align: center;
  font-size: 1.5rem;
  /*margin: 0 auto;*/
  margin-left: 18px;
  color: #a4241c;
}

.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
.page[size='A4'] {
  width: 21cm;
  height: 29.686cm;
}
.half-page {
  height: 50%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}
.half-page:first-child {
  border-bottom: 1px dashed black;
}

footer {
  width: 100%;
  /*height: 10mm;*/
  display: flex;
  justify-content: space-around;
}
footer div {
  margin: 1rem;
  display: flex;
  align-items: center;
}
footer p {
  font-size: 14px;
}

@media print {
  .page {
    box-shadow: none;
    margin: 0;
  }
}
</style>
